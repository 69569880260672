@use 'core/styles/container';

.root {
  height: 100%;
  @extend .container;
  margin: 0 auto;

  &.fullWidth {
    width: 100%;
    max-width: 100%;
  }
}

.backButtonContainer {
  padding: 20px 20px 0;
}

.backButtonButton {
  font-size: 10px;
  padding: 9px 12px;
  height: auto;
}
