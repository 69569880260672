.root {
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  position: relative;
  transition: opacity 0.1s ease-in-out;
}

.inputOuterContainer {
  position: relative;
}

.placeholderContainer {
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
  justify-content: center;
  pointer-events: none;
  height: 100%;
}

.placeholder {
  color: var(--input-placeholder-color);
  background-color: var(--input-background-color);
  position: relative;
  top: 15px;
  padding: 0 4px;
  transition: top 0.15s ease-in-out, font-size 0.15s ease-in-out,
    font-weight 0.15s ease-in-out;
  white-space: nowrap;
  font-size: var(--font-size-16px, 16px);
}

.inputContainer {
  background-color: var(--input-background-color);
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius);
  min-height: 46px;
  position: relative;
}

.input {
  width: 100%;
  height: 100%;
  min-height: 46px;
  appearance: none;
  border-width: 0;
  outline: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: var(--border-radius);
  font-size: var(--font-size-16px, 16px);
  line-height: var(--line-height-for-16px);
}

.svgIcon {
  fill: var(--input-border-color);
}

.helpText {
  font-size: var(--font-size-14px, 14px);
  line-height: var(--line-height-for-14px);
  margin: 10px 0 0 13px;
}

.charsLeft {
  margin: 6px 0 0 13px;
  color: var(--text-lighter-color);
  font-size: var(--font-size-12px, 12px);
  line-height: var(--line-height-for-12px);
}

.charsLeftDanger {
  color: var(--danger-color) !important;
}

.rightIcons {
  top: 0;
  right: 0;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.rightIcon {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

.errors {
  font-size: var(--font-size-14px, 14px);
  padding: 12px 14px;
  background-color: var(--input-error-background-color);
  color: var(--input-error-color);
  line-height: 23px;
}

// Modifiers
.root {
  &.disabled {
    opacity: 0.75;
    pointer-events: none;
  }

  &.hasValue {
    &.error {
      .placeholder {
        color: var(--input-error-color) !important;
      }
    }

    .placeholder {
      font-size: var(--font-size-12px, 12px);
      font-weight: var(--font-weight-medium);
      top: -6px;
    }
  }

  &:focus-within {
    &.error {
      .placeholder {
        color: var(--input-error-color) !important;
      }
    }

    .placeholder {
      font-size: var(--font-size-12px, 12px);
      font-weight: var(--font-weight-medium);
      top: -6px;
      color: var(--input-focus-color);
    }

    .inputContainer {
      border-width: 1px;
      border-color: var(--input-focus-color);
    }
  }

  &.error {
    .input {
      color: var(--input-error-color) !important;
    }

    .placeholder {
      color: var(--input-error-color) !important;
    }

    .inputContainer {
      border-width: 1px;
      border-color: var(--input-error-color) !important;
    }

    .svgIcon {
      fill: var(--danger-color);
    }
  }

  &.hasRightIcon {
    .input {
      padding-right: 40px;
    }
  }

  &.multiline {
    .inputContainer {
      padding-top: 10px;
      padding-bottom: 7px;
    }

    .input {
      font-size: var(--font-size-16px, 16px);
      line-height: var(--line-height-for-16px);
    }
  }
}

// Hover & active states (ignoring mobile)
:global(.canHover) {
  .root {
    &:focus-within {
      .inputContainer {
        &:hover {
          border-color: var(--input-focus-color);
        }
      }

      .placeholder {
        color: var(--input-focus-color);
      }

      &:hover {
        .placeholder {
          color: var(--input-focus-color);
        }
      }

      &.error {
        .inputContainer {
          &:hover {
            border-color: var(--input-focus-color);
          }
        }
      }
    }

    &.error {
      .inputContainer {
        &:hover {
          border-color: var(--input-error-color);
        }
      }
    }

    &:hover {
      .placeholder {
        color: var(--input-border-hover-color);
      }
    }
  }

  .inputContainer {
    &:hover {
      border-color: var(--input-border-hover-color);
    }
  }
}
