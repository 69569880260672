@keyframes skeleton-loading {
  from {
    background-position: 200% 0;
  }

  to {
    background-position: -200% 0;
  }
}

.root {
  background-color: var(--skeleton-background-color);
  height: 100%;
  display: inline-block;
  // width: 100%;
  background-image: linear-gradient(
    270deg,
    var(--skeleton-color-1),
    var(--skeleton-color-2),
    var(--skeleton-color-2),
    var(--skeleton-color-1)
  );
  background-size: 400% 100%;
  animation: skeleton-loading 8s ease-in-out infinite;

  &:before {
    position: relative;
    width: 0;
    content: '-';
    visibility: hidden;
  }

  &.variantRect {
    border-radius: var(--border-radius);
  }

  &.variantCircle {
    border-radius: 50%;
    height: 100%;
  }
}

.content {
  visibility: hidden;
}
