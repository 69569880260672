@use 'core/styles/breakpoint';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  @include breakpoint.above(mobile) {
    width: auto;
  }

  a {
    font-weight: var(--font-weight-bold);

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: var(--link-color);
      text-decoration: none;
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}

.container {
  border-radius: var(--border-radius);
  width: 100%;

  @include breakpoint.above(mobile) {
    border: 1px solid var(--border-color);
    padding: 30px 50px;
  }
}

.heading {
  font-size: var(--font-size-40px, 40px);
  font-weight: var(--font-weight-extra-bold);
  margin: 0;
  text-align: center;
  letter-spacing: -1px;
}

.intro {
  margin-top: 16px;
  margin-bottom: 40px;
  text-align: center;
  font-size: var(--font-size-16px, 16px);
  line-height: var(--line-height-for-16px);
}

.intro p:first-of-type {
  margin-top: 0;
}

.intro p:last-of-type {
  margin-bottom: 0;
}

.content {
  width: 100%;
  margin: 0 auto;

  @include breakpoint.above(mobile) {
    width: 320px;
  }
}

.extra {
  margin-top: 30px;
  text-align: center;
  font-size: var(--font-size-16px, 16px);
  line-height: var(--line-height-for-16px);
}

.footer {
  font-size: var(--font-size-12px, 12px);
  margin-top: 50px;
  text-align: center;
  line-height: var(--line-height-for-12px);
  color: #888;
}
