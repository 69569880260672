$breakpoints: (
  mobile: 600px,
  tablet: 860px,
  desktop: 960px,
  monitor: 1280px,
);

@mixin above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin abovePx($px) {
  @media (min-width: $px) {
    @content;
  }
}

@mixin belowPx($px) {
  @media (max-width: $px) {
    @content;
  }
}
